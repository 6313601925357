import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-i-am-cormac',
  templateUrl: './i-am-cormac.component.html',
  styleUrls: ['./i-am-cormac.component.scss']
})
export class IAmCormacComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
